import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/react'
import {CheckCircleIcon, ExclamationCircleIcon} from '@heroicons/react/20/solid'
import {EllipsisVerticalIcon, LockClosedIcon} from '@heroicons/react/24/solid'
import {useMemo, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {GroupIcon} from '~/assets/GroupIcon.tsx'
import {PlusIcon} from '~/assets/PlusIcon.tsx'
import {DeleteConfirmationModal} from '~/components/DeleteConfirmationModal.tsx'
import {ErrorModal} from '~/components/ErrorModal.tsx'
import {LoadingAnimation} from '~/components/LoadingAnimation.tsx'
import {type DataSet} from '~/model.ts'
import {DataSetCreate} from '~/pages/dataSets/DataSetCreate.tsx'
import {GlobalDataSetInfo} from '~/pages/dataSets/GlobalDataSetInfo.tsx'
import {useCreateDataSet, useDataSets, useDeleteDataSet, useProjects} from '~/services/api.ts'
import {capitalizeFirstChar, formatDate} from '~/util.ts'

export const DataSets = () => {
	const navigate = useNavigate()

	const {data: dataSets, isLoading} = useDataSets()
	const {data: projects} = useProjects()
	const {mutateAsync: createDataSet, isPending: createDataSetIsPending} = useCreateDataSet()
	const {mutate: deleteDataSetApi} = useDeleteDataSet()

	const [createDataSetOpen, setCreateDataSetOpen] = useState(false)
	const [deleteDataSet, setDeleteDataSet] = useState<DataSet | null>(null)
	const [viewGlobalDataSet, setViewGlobalDataSet] = useState<DataSet | null>(null)
	const [cantDeleteDataSet, setCantDeleteDataSet] = useState(false)

	const personalDataSetsSortedByModifiedDatetime = useMemo(() => dataSets?.personalDataSets.slice().sort((a, b) => (a.modified_datetime > b.modified_datetime ? -1 : 1)), [dataSets])

	const onCreateDataSet = (dataSet: DataSet) => {
		setCreateDataSetOpen(false)
		void createDataSet(dataSet).then((response) => {
			navigate(`/datasets/${response.SK}/files`)
		})
	}

	const onDeleteDataSet = (dataSet: DataSet) => {
		if (projects == null) return
		const projectsUsingDataSet = projects.filter((project) => project.dataset === dataSet.SK)
		if (projectsUsingDataSet.length > 0) {
			setCantDeleteDataSet(true)
		}
		setDeleteDataSet(dataSet)
	}

	if (createDataSetIsPending) {
		return (
			<div className="flex items-center justify-center">
				<LoadingAnimation />
			</div>
		)
	}

	return (
		<>
			<div className="h-full overflow-y-auto px-[120px] py-[24px]">
				<h1 className="font-display text-2xl">Data sets</h1>
				<p className="mt-[8px] max-w-content text-[18px] text-dark-grey-100 dark:text-light-grey-50">
					Data sets are curated information pools. When creating a project, choose either a global or custom data set. The AI model in your project will use the selected data set as a reference.
				</p>
				{isLoading ? (
					<div className="flex items-center justify-center">
						<LoadingAnimation />
					</div>
				) : (
					<>
						<h2 className="mt-[48px] text-lg font-[600] light:text-dark-grey-100">Custom data sets</h2>
						<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">Only you have access to the custom data sets that you create.</p>
						<div className="mt-[24px] flex flex-wrap gap-[24px]">
							{personalDataSetsSortedByModifiedDatetime?.map((dataSet) => (
								<Link
									key={dataSet.name}
									to={`/datasets/${dataSet.SK}`}
									className="w-[384px] rounded-[8px] border border-dark-grey-50 bg-white p-[24px] ring-1 ring-transparent hover:border-dark-grey-100 active:ring-dark-grey-100 dark:border-navy-50 dark:bg-navy-100 dark:hover:border-white dark:active:ring-white"
								>
									<div className="flex items-center justify-between">
										<div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-[600]">{dataSet.name}</div>
										{dataSet.role !== 'VIEWER' && (
											<Menu
												as="div"
												className="relative"
											>
												<MenuButton className="mx-[4px] flex items-center justify-center rounded-full border border-transparent p-[2px] hover:bg-light-grey-25 data-[open]:bg-light-grey-25 dark:hover:bg-navy-75 dark:data-[open]:bg-navy-75">
													<EllipsisVerticalIcon className="h-[20px]" />
												</MenuButton>
												<MenuItems className="absolute left-[2px] top-[20px] z-10 mt-2 w-[200px] rounded-[6px] border bg-white p-1 text-sm shadow-lg ring-black ring-opacity-5 dark:border-none dark:bg-navy-75">
													<MenuItem>
														<button
															onClick={(event) => {
																event.preventDefault()
																navigate(`/datasets/${dataSet.SK}?rename`)
															}}
															className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
														>
															Rename data set
														</button>
													</MenuItem>
													{dataSet.role === 'OWNER' && (
														<MenuItem>
															<button
																onClick={(event) => {
																	onDeleteDataSet(dataSet)
																	event.preventDefault()
																}}
																className="block w-full rounded-[6px] px-4 py-2 text-left text-dark-grey-100 data-[active]:bg-light-grey-25 dark:text-white dark:data-[active]:bg-navy-100"
															>
																Delete data set
															</button>
														</MenuItem>
													)}
												</MenuItems>
											</Menu>
										)}
									</div>
									<div className="my-[8px] flex items-center justify-start gap-[8px] text-dark-grey-75 dark:text-light-grey-50">
										{dataSet.status === 'READY' ? <CheckCircleIcon className="size-[16px] light:text-navy-100" /> : <ExclamationCircleIcon className="size-[16px] light:text-navy-100" />}
										Status: {dataSet.status === 'READY' ? 'Indexed' : capitalizeFirstChar(dataSet.status)}
									</div>
									<div className="text-dark-grey-75 dark:text-light-grey-50">Last update: {formatDate(dataSet.modified_datetime)}</div>
									<div className="text-dark-grey-75 dark:text-light-grey-50">Type: Chat</div>
									<div className="mt-[12px] flex max-w-max items-center justify-start gap-[4px] rounded-[4px] bg-light-grey-25 px-[8px] py-[2px] dark:bg-navy-75">
										<GroupIcon className="size-[16px]" />
										<div className="text-sm">{capitalizeFirstChar(dataSet.role)}</div>
									</div>
								</Link>
							))}
							<button
								onClick={() => {
									setCreateDataSetOpen(true)
								}}
								className="flex min-h-[178px] w-[384px] items-center justify-center gap-x-[8px] rounded-[8px] border border-dashed border-dark-grey-50 bg-white p-[24px] hover:border-dark-grey-100 active:border-2 active:border-navy-100 dark:border-navy-50 dark:bg-navy-100 dark:text-white dark:hover:border-white"
							>
								<PlusIcon className="h-[20px]" />
								<div className="text-sm font-[600]">Add data set</div>
							</button>
						</div>
						<h2 className="mt-[48px] text-lg font-[600] light:text-dark-grey-100">Global data sets</h2>
						<p className="mt-[8px] text-dark-grey-75 dark:text-light-grey-50">Global data sets are available to all UniMelb staff. At this stage, you cannot add or edit global data sets.</p>
						<div className="mt-[24px] flex flex-wrap gap-[24px]">
							{dataSets?.globalDataSets.map((dataSet) => (
								<button
									key={dataSet.SK}
									className="w-[384px] rounded-[8px] border border-dark-grey-50 bg-white p-[24px] text-left ring-1 ring-transparent hover:border-dark-grey-100 active:ring-dark-grey-100 dark:border-navy-50 dark:bg-navy-100 dark:hover:border-white dark:active:ring-white"
									onClick={() => {
										setViewGlobalDataSet(dataSet)
									}}
								>
									<div className="flex items-center justify-between">
										<div className="flex items-center gap-x-[8px] overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-[600] dark:text-white">
											<LockClosedIcon className="h-[16px]" />
											<div>{dataSet.name}</div>
										</div>
									</div>
									<div className="my-[8px] flex items-center justify-start gap-[8px] text-dark-grey-75 dark:text-light-grey-50">
										{dataSet.status === 'READY' ? <CheckCircleIcon className="size-[16px] text-navy-100" /> : <ExclamationCircleIcon className="size-[16px] text-navy-100" />}
										Status: {dataSet.status === 'READY' ? 'Indexed' : capitalizeFirstChar(dataSet.status)}
									</div>
									<div className="text-dark-grey-75 dark:text-light-grey-50">Last update: {formatDate(dataSet.modified_datetime)}</div>
									<div className="text-dark-grey-75 dark:text-light-grey-50">Type: Chat</div>
									<div className="mt-[12px] flex max-w-max items-center justify-start gap-[4px] rounded-[4px] bg-light-grey-25 px-[8px] py-[2px] dark:bg-navy-75">
										<GroupIcon className="size-[16px]" />
										<div className="text-sm">Global</div>
									</div>
								</button>
							))}
						</div>
					</>
				)}
			</div>

			<DataSetCreate
				open={createDataSetOpen}
				onCancel={() => {
					setCreateDataSetOpen(false)
				}}
				onCreateDataSet={onCreateDataSet}
			/>

			<DeleteConfirmationModal
				open={deleteDataSet != null}
				itemType="data set"
				onClose={() => {
					setDeleteDataSet(null)
				}}
				onDelete={() => {
					if (deleteDataSet != null) {
						deleteDataSetApi(deleteDataSet)
					}
					setDeleteDataSet(null)
				}}
			/>

			<GlobalDataSetInfo
				dataSet={viewGlobalDataSet ?? undefined}
				onDone={() => {
					setViewGlobalDataSet(null)
				}}
			/>

			<ErrorModal
				title="You can't delete this data set"
				open={cantDeleteDataSet}
				onClose={() => {
					setCantDeleteDataSet(false)
					setDeleteDataSet(null)
				}}
			>
				<p>This data set is linked to at least one project.</p>
				<p className="mt-[1rem]">You can only delete data sets that are not linked to any projects.</p>
			</ErrorModal>
		</>
	)
}
